// Generated by Framer (7aa0232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Gg9qw26aF", "DJO6xyTiV"];

const variantClassNames = {DJO6xyTiV: "framer-v-189w92f", Gg9qw26aF: "framer-v-wyz8w0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "Gg9qw26aF", "Variant 2": "DJO6xyTiV"}

const getProps = ({background, height, id, width, ...props}) => { return {...props, toaKH4Vnc: background ?? props.toaKH4Vnc ?? "rgb(19, 88, 73)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Gg9qw26aF"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, toaKH4Vnc, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Gg9qw26aF", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-gaSob", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-wyz8w0", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Gg9qw26aF"} ref={ref} style={{backgroundColor: toaKH4Vnc, borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, boxShadow: "0px 0.6021873017743928px 0.6021873017743928px -0.9166666666666666px rgba(0, 0, 0, 0.09792), 0px 2.288533303243457px 2.288533303243457px -1.8333333333333333px rgba(0, 0, 0, 0.09488), 0px 10px 10px -2.75px rgba(0, 0, 0, 0.081)", ...style}} {...addPropertyOverrides({DJO6xyTiV: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 1355, intrinsicWidth: 1982, pixelHeight: 1355, pixelWidth: 1982, sizes: "min(405px, 100vw)", src: new URL("assets/ggZg9HklcJqGthfmj7y8IqIupw.png", import.meta.url).href, srcSet: `${new URL("assets/512/ggZg9HklcJqGthfmj7y8IqIupw.png", import.meta.url).href} 512w, ${new URL("assets/1024/ggZg9HklcJqGthfmj7y8IqIupw.png", import.meta.url).href} 1024w, ${new URL("assets/ggZg9HklcJqGthfmj7y8IqIupw.png", import.meta.url).href} 1982w`}} className={"framer-1e0ie79"} layoutDependency={layoutDependency} layoutId={"zkVnPPXLr"} {...addPropertyOverrides({DJO6xyTiV: {background: {alt: "", fit: "fill", intrinsicHeight: 1355, intrinsicWidth: 1982, pixelHeight: 1355, pixelWidth: 1982, sizes: "min(405px, 100vw)", src: new URL("assets/b7oRY0jVAY9bxq4ZiueJViatkI.png", import.meta.url).href, srcSet: `${new URL("assets/512/b7oRY0jVAY9bxq4ZiueJViatkI.png", import.meta.url).href} 512w, ${new URL("assets/1024/b7oRY0jVAY9bxq4ZiueJViatkI.png", import.meta.url).href} 1024w, ${new URL("assets/b7oRY0jVAY9bxq4ZiueJViatkI.png", import.meta.url).href} 1982w`}}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-gaSob [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gaSob .framer-6hc23t { display: block; }", ".framer-gaSob .framer-wyz8w0 { height: 507px; overflow: hidden; position: relative; width: 405px; will-change: var(--framer-will-change-override, transform); }", ".framer-gaSob .framer-1e0ie79 { align-content: center; align-items: center; bottom: 0px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 24px; justify-content: flex-start; left: 0px; overflow: visible; padding: 0px 0px 0px 0px; position: absolute; top: 0px; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gaSob .framer-1e0ie79 { gap: 0px; } .framer-gaSob .framer-1e0ie79 > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } .framer-gaSob .framer-1e0ie79 > :first-child { margin-top: 0px; } .framer-gaSob .framer-1e0ie79 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 507
 * @framerIntrinsicWidth 405
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"DJO6xyTiV":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"toaKH4Vnc":"background"}
 */
const FramerQ62T23Rih: React.ComponentType<Props> = withCSS(Component, css, "framer-gaSob") as typeof Component;
export default FramerQ62T23Rih;

FramerQ62T23Rih.displayName = "img-cec-care-3";

FramerQ62T23Rih.defaultProps = {height: 507, width: 405};

addPropertyControls(FramerQ62T23Rih, {variant: {options: ["Gg9qw26aF", "DJO6xyTiV"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, toaKH4Vnc: {defaultValue: "rgb(19, 88, 73)", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerQ62T23Rih, [])